.loginBtn {
  width: 330px !important;
}

.formControl {
  border: 2px solid #1b97d4 !important;
  border-radius: 5px;
}

.disclaimerIcon {
  font-size: 4.5rem;
}

.disclaimerTextAlignment {
  text-align: justify;
}